import * as React from "react"
import { useState } from "react"
import Modal from "react-bootstrap/Modal"
import AxiosInstance from "../../../services/axiosInstance"
import { navigate } from "gatsby-link"

const CodeModal = ({ codeShow, setCodeShow, driveAll }) => {
  const [codeName, setCodeName] = useState("")
  const handleClose = () => {
    setCodeShow(false)
  }
  const handleSubmit = event => {
    if (codeName) {
      return new Promise(async resolve => {
        await AxiosInstance.post(`/drive/code/add`, {
          docName: codeName,
          parentDoc: 0,
          parentID: driveAll ? driveAll.id : 0,
        })
          .then(function (response) {
            handleClose()
            if (response.status === 201) {
              console.log(response.status)
            }
            navigate(`/drive/code/${response.data.driveAll}`)
          })
          .catch(function (error) {
            console.log(error)
          })
      })
    }
  }
  return (
    <Modal show={codeShow} onHide={handleClose}>
      <Modal.Header>
        <h5 className="modal-title" id="newCodeModalTitle">
          <i className="bi bi-code" /> Code
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        />
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3 row align-items-center">
          <label htmlFor="codeName" className="col-sm-4 col-form-label">
            Code Name
          </label>
          <div className="col-sm-8">
            <input
              type="text"
              className="form-control"
              name="codeName"
              value={codeName}
              placeholder="Name"
              onChange={e => {
                setCodeName(e.target.value)
              }}
              onMouseLeave={e => {
                setCodeName(e.target.value)
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-default" onClick={handleClose}>
          <i className="bi bi-x-lg" /> Cancel
        </button>
        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}>
          <i className="bi bi-check-lg" /> Save
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default CodeModal
